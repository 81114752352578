<template>
    <v-container fill-height class="white d-flex align-start" fluid>
    <v-row class="d-flex align-start" justify="center" align="start" >
        <menus  fondo="false" />
        <v-col cols="12" md="7">
            <div class="text-center">
                <b>PERFILES PUBLICADOS ACTUALMENTE EN TODA LA CIUDAD</b>
                <div class="font-weight-black my-10">
                    <v-responsive :aspect-ratio="9/14" align="center">
                        <v-img
                            min-height="350"
                            max-width="500"
                            :src="require('../assets/mapa.png')"
                        >
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded                            
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded                            
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top:'+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="accent"
                                elevation="23"
                                fab
                                rounded
                                :style="'position: relative; top: '+ valorx2() +'%; left:'+ valorx1() +'%'"
                            >
                                <v-icon>
                                mdi-account-circle
                                </v-icon>
                            </v-btn>
                        </v-img>
                    </v-responsive>
                </div>
                <v-spacer/>
            </div>    
        </v-col>        
        </v-row>
    </v-container>

</template>
<script>

    import menus from '../components/menus.vue'
    export default {
        name: 'Publicados',
        components: {
            menus
        },
        data: () => ({
            uvalor: 0,
            uvalor2: 0,
            
        }),
        methods: {
            valorx1(){
                let a = Math.floor(Math.random() * 10)
                if(this.uvalor != 0){
                    while(Math.abs(this.uvalor - a) <= 8){
                        a = Math.floor(Math.random() * 18)
                    }
                }
                this.uvalor = a
                return a
            },
            valorx2(){
                let a = Math.floor(Math.random() * 70)
                if(this.uvalor2 != 0){
                    while(Math.abs(this.uvalor2 - a) <= 8){
                        a = Math.floor(Math.random() * 18)
                    }
                }
                this.uvalor2 = a
                return a
            }
        },
        computed: {
            
        }
    }
</script>
<style>

</style>
